import React, { createContext, useContext, useEffect, useState } from 'react';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import responsiveFontSizes from '@mui/material/styles/responsiveFontSizes';
import { StyledEngineProvider } from '@mui/material/styles';
import { GlobalStyles } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import customTheme from './components/customTheme';
import customDarkTheme from './components/customThemeDark';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import routes from './routes';
import NRLayout from './components/NRLayout/NRLayout';
import { initPendo } from './utils/pendo.utils';
import { useAuth0 } from '@auth0/auth0-react';
import NRLoadingPage from './pages/NRLoadingPage/NRLoadingPage';
import { LAST_ORG_USED_KEY } from './utils/ApiConstants';
import NRReportTabsContextWrapper from './pages/NRReportTabs/NRReportTabsContextWrapper';
import NRReportFilterContextWrapper from './pages/NRReportTabs/NRReportFilterContextWrapper';
import NRSBOMDataShare from './pages/NRSBOMTable/NRSBOMDataShare';
import NRComponentContextWrapper from './ContextWrappers/NRComponentContextWrapper';
import NRAssetsDataShare from './DataShare/NRAssetsDataShare';
import { DARK_APPEARANCE, LIGHT_APPEARANCE, SELECTED_APPEARANCE, SYSTEM_APPEARANCE } from './constants/constants';
import NRSnackbarProvider from './ContextWrappers/NRSnackbarProvider';
import NRRemediationContextWrapper from './components/NRRemediation/NRRemediationContext';
import NRReactGridContextWrapper from './components/NRReactGrid/NRReactGridContext';

export const UserContext = createContext({
  currentOrg: null,
  setCurrentOrg: () => {},
  currentOrgId: null,
  setCurrentOrgId: () => {},
  orgs: null,
  setOrgs: () => {},
  roles: null,
  setRoles: () => {},
  email: null,
  setEmail: () => {},
  isChangingOrg: null,
  setIsChangingOrg: () => {},
  appearanceOptionName: null,
  setAppearanceOptionName: () => {}
});

const theme = responsiveFontSizes(customTheme);
const darkTheme = responsiveFontSizes(customDarkTheme);
const CheckAuthentication = ({ element }) => {
  const { setCurrentOrg, setCurrentOrgId, setOrgs, setRoles, setEmail, isChangingOrg } = useContext(UserContext);
  const { isAuthenticated, loginWithRedirect, getAccessTokenSilently, getIdTokenClaims, isLoading } = useAuth0();
  const [loggedIn, setLoggedIn] = useState(false);

  const getTokens = async () => {
    let idToken = await getIdTokenClaims({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE
    });
    const auth0Namespace = process.env.REACT_APP_AUTH0_NAMESPACE;
    const orgsNamespace = auth0Namespace + 'orgs';
    const currentOrgNamespace = auth0Namespace + 'org_name';
    const rolesNamespace = auth0Namespace + 'roles';

    if (!idToken.org_id) {
      const lastOrgUsedId = localStorage.getItem(idToken.email + LAST_ORG_USED_KEY);
      let userOrg;

      if (!!lastOrgUsedId && !!idToken[orgsNamespace]?.findIndex(org => org.Id === lastOrgUsedId) !== -1) {
        userOrg = lastOrgUsedId;
      } else if (!!idToken[orgsNamespace]?.length) {
        userOrg = idToken[orgsNamespace][0]?.Id;
      }

      try {
        idToken = await getAccessTokenSilently({
          ignoreCache: true,
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          organization: userOrg
        });
        idToken = await getIdTokenClaims({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE
        });
      } catch (e) {
        console.log(e);
      }
      setLoggedIn(true);
    } else {
      setLoggedIn(true);
    }
    if (process.env.REACT_APP_ENVIRONMENT !== 'local') {
      initPendo(idToken);
    }

    setOrgs(idToken[orgsNamespace]);
    setEmail(idToken?.email);
    setCurrentOrgId(idToken.org_id);
    if (!!idToken[currentOrgNamespace] && idToken[rolesNamespace]) {
      setCurrentOrg(idToken[currentOrgNamespace]);
      setRoles(idToken[rolesNamespace]);
    }

    return idToken;
  };

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect();
    } else if (isAuthenticated && !isLoading) {
      getTokens();
    }
  }, [isAuthenticated, isLoading]);

  return (
    <>
      {loggedIn && !isChangingOrg && element}
      {(!loggedIn || isChangingOrg) && <NRLoadingPage />}
    </>
  );
};

const RoutesHandler = () => (
  <BrowserRouter>
    <Routes>
      <Route
        element={
          <NRComponentContextWrapper>
            <NRAssetsDataShare>
              <NRSBOMDataShare>
                <NRReactGridContextWrapper>
                  <NRRemediationContextWrapper>
                    <NRLayout>
                      <NRReportTabsContextWrapper>
                        <NRReportFilterContextWrapper>
                          <Outlet />
                        </NRReportFilterContextWrapper>
                      </NRReportTabsContextWrapper>
                    </NRLayout>
                  </NRRemediationContextWrapper>
                </NRReactGridContextWrapper>
              </NRSBOMDataShare>
            </NRAssetsDataShare>
          </NRComponentContextWrapper>
        }
      >
        {routes.map(({ path, exact, element, ...props }) => (
          <Route path={path} exact={exact} {...props} element={<CheckAuthentication element={element} props={props} />} key={`route-${path}`} />
        ))}
      </Route>
    </Routes>
  </BrowserRouter>
);

export const getSelectedAppearance = email => {
  return localStorage.getItem(`${email}${SELECTED_APPEARANCE}`);
};

export const setSelectedAppearance = (email, value) => {
  return localStorage.setItem(`${email}${SELECTED_APPEARANCE}`, value);
};

function App() {
  const [currentOrg, setCurrentOrg] = useState();
  const [currentOrgId, setCurrentOrgId] = useState();
  const [orgs, setOrgs] = useState();
  const [roles, setRoles] = useState();
  const [email, setEmail] = useState();
  const [isChangingOrg, setIsChangingOrg] = useState();
  const [appearanceOptionName, setAppearanceOptionName] = useState(SYSTEM_APPEARANCE);

  const [selectedDarkTheme, setSelectedDarkTheme] = useState(setAppearance(appearanceOptionName));

  function setAppearance(themeSelector) {
    switch (themeSelector) {
      case DARK_APPEARANCE:
        return true;
      case LIGHT_APPEARANCE:
        return false;
      case SYSTEM_APPEARANCE:
        return window.matchMedia('(prefers-color-scheme: dark)').matches;
      default:
        return false;
    }
  }

  useEffect(() => {
    !!email && setAppearanceOptionName(getSelectedAppearance(email) || LIGHT_APPEARANCE);
  }, [email]);

  useEffect(() => {
    setSelectedDarkTheme(setAppearance(appearanceOptionName));
  }, [appearanceOptionName]);

  const defaultValues = {
    currentOrg,
    setCurrentOrg,
    currentOrgId,
    setCurrentOrgId,
    orgs,
    setOrgs,
    roles,
    setRoles,
    email,
    setEmail,
    isChangingOrg,
    setIsChangingOrg,
    appearanceOptionName,
    setAppearanceOptionName
  };
  // in order to change the theme on the fly when set to 'system'
  !!email &&
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', ({ matches }) => {
      if (getSelectedAppearance(email) === SYSTEM_APPEARANCE) {
        setSelectedDarkTheme(matches);
      }
    });

  return (
    <StyledEngineProvider injectFirst>
      <UserContext.Provider value={defaultValues}>
        <ThemeProvider theme={selectedDarkTheme ? darkTheme : theme}>
        <CssBaseline enableColorScheme />
          <GlobalStyles
            styles={{
              body: {
                fontSize: '0.875rem',
                lineHeight: 1.43,
                margin: 0,
                fontFamily: `'Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif', '-apple-system'`,
                WebkitFontSmoothing: 'antialiased',
                MozOsxFontSmoothing: 'grayscale'
              }
            }}
          />
          <NRSnackbarProvider>
            <RoutesHandler />
          </NRSnackbarProvider>
        </ThemeProvider>
      </UserContext.Provider>
    </StyledEngineProvider>
  );
}

export default App;
