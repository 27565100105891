import { useAuth0 } from '@auth0/auth0-react';
import Popover from '@mui/material/Popover';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { CONSTANTS } from '../../constants/constants';
import { GET_ASSET } from '../../graphql/queries/asset';
import useNRQuery from '../../graphql/useNRQuery';
import ReportPopover from './ReportPopover';
const { COPIED, MD5_HASH, SHA256_HASH } = CONSTANTS.REPORT.POPOVER;

function ReportPopoverContainer({ anchorEl, setAnchorEl, componentId, popoverClassesOverride }) {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth0();

  const auth0Namespace = process.env.REACT_APP_AUTH0_NAMESPACE;
  const orgNamespace = auth0Namespace + 'org_name';
  const [org, setOrg] = useState(null);

  const [reportData, isReportDataLoading] = useNRQuery({
    query: GET_ASSET,
    options: { variables: { args: { assetId: componentId?.replaceAll('%7C', '|') } }, context: { version: 'v3' } },
    qlObjKey: 'asset',
    transformData: resp => {
      return { endTime: resp.endTime, digest: resp?.component?.digest };
    }
  });

  useEffect(() => {
    !!user && setOrg(user[orgNamespace]);
  }, [user, reportData]);

  const onCopySHA256 = () => {
    navigator.clipboard.writeText(reportData?.digest?.sha256);
    enqueueSnackbar(SHA256_HASH + COPIED, { variant: 'success', preventDuplicate: true, autoHideDuration: 1000 });
  };

  const onCopyMD5 = () => {
    navigator.clipboard.writeText(reportData?.digest?.md5);
    enqueueSnackbar(MD5_HASH + COPIED, { variant: 'success', preventDuplicate: true, autoHideDuration: 1000 });
  };

  return (
    <Popover
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={() => setAnchorEl(null)}
      classes={popoverClassesOverride}
    >
      <ReportPopover reportData={reportData} org={org} isReportDataLoading={isReportDataLoading} onCopySHA256={onCopySHA256} onCopyMD5={onCopyMD5} />
    </Popover>
  );
}
export default ReportPopoverContainer;
